const _payrollStatus = {
  PENDING_APPROVAL: "pendingApproval",
  PROCESSING: "processing",
  COMPLETED: "completed",
  APPROVED: "approved",
  UNAPPROVED: "unapproved",
  SUCCESS: "success",
  FAILED: "failed",
};

export const payrollStatus = Object.freeze(_payrollStatus);

export const getPayrollStatus = status => {
  switch (status) {
    case payrollStatus.PENDING_APPROVAL:
      return "Pending";
    case payrollStatus.PROCESSING:
      return "Processing";
    case payrollStatus.COMPLETED:
      return "Completed";
    case payrollStatus.APPROVED:
      return "Approved";
    case payrollStatus.UNAPPROVED:
      return "Unapproved";
    case payrollStatus.SUCCESS:
      return "Success";
    case payrollStatus.FAILED:
      return "Failed";

    default:
      return "Pending";
  }
};

export const getCreatePayrollStatus = status => {
  switch (status) {
    case payrollStatus.PENDING_APPROVAL:
      return "Approve payroll";
    case payrollStatus.APPROVED:
      return "Make payment";

    case payrollStatus.FAILED:
      return "Retry payment";

    default:
      return "Create payroll";
  }
};

export const getShouldShowPayrollButton = status => {
  switch (status) {
    case payrollStatus.COMPLETED || payrollStatus.SUCCESS:
      return false;

    default:
      return true;
  }
};
