import client from "../../../config/apiConfig/apiConfig";
import { removeEmptyKeyValuesFromObject } from "../../../utils/objectHelperFunctions";

import { debugPrint } from "../../../utils/debugPrint";

/// Function responsible for completing the onboarding process of a school
export const completeOnboarding = async payload => {
  const response = client.schoolClient.post("school-onboarding", payload);

  return response;
};

export const fetchSchoolDetails = async () => {
  const response = client.schoolClient.get("get-school-details");

  return response;
};

/// This functions is responsible for fetching all school subjects
export const fetchAllSubjects = async (pageNumber = 1) => {
  const queryParameter = {
    page: +pageNumber,
  };

  const response = client.schoolClient.get("get-all-subjects", {
    params: queryParameter,
  });

  return response;
};

export const deleteSchoolSubject = async payload => {
  const response = client.schoolClient.delete(
    "delete-subject-from-school-subjects",
    { data: payload }
  );

  return response;
};

export const fetchClassSubject = async studentClass => {
  const queryParameters = {
    studentClass,
  };

  const response = client.schoolClient.get("get-subjects-by-class", {
    params: queryParameters,
  });

  return response;
};

/// ------------------------ STUDENT - STARTS ------------------------ ///
/// Function responsible for adding new students to the database
export const addStudent = async payload => {
  const response = client.schoolClient.post("student-registration", payload);

  return response;
};

export const getAllFilteredStudents = async (
  page,
  filterClass,
  filterTerm,
  filterYear
) => {
  page = parseInt(page);

  // let link = `https://bt-school-service.herokuapp.com/sort-students?page=${page}`;
  let link = `sort-students?page=${page}`;

  if (filterClass === "" || filterClass === undefined) {
  } else {
    link = `${link}&studentClass=${filterClass}`;
  }
  if (filterTerm === "" || filterTerm === undefined) {
  } else {
    link = `${link}&studentTerm=${filterTerm}`;
  }
  if (filterYear === "" || filterYear === undefined) {
  } else {
    link = `${link}&studentYear=${filterYear}`;
  }

  const response = client.schoolClient.get(link);

  return response;
};

/**
 * @description - The function handles fetching students from the DB
 * @param {integer} page - queryParameter for the current page of the query
 * @return {Promise<AxiosResponse<any, any>>}
 */
export const getAllStudents = async (page = 1) => {
  const response = client.schoolClient.get("get-all-students", {
    params: { page: page },
  });

  return response;
};
/// ------------------------ STUDENT - ENDS ------------------------ ///

export const addTeacher = async payload => {
  const response = client.schoolClient.post("teacher-registration", payload);

  return response;
};

/// Function responsible for deleting teacher's data
export const deleteTeacher = async teacherAuthId => {
  const body = {
    teacherAuthId: teacherAuthId,
  };

  const response = client.schoolClient.delete("delete-teacher", { data: body });

  return response;
};

// ------------------- HANDLE EXAM TYPES - BEGINS ------------------- //

/// Function responsible for adding new exam types
export const addExamType = async payload => {
  const response = client.schoolClient.post("create-exam-type", payload);

  return response;
};

/// Function for getting all the exam types a school offers
export const getAllExamTypes = async (page = 1) => {
  const queryParameter = {
    page: +page,
  };

  const response = client.schoolClient.get("get-all-exam-types", {
    params: queryParameter,
  });
  return response;
};

/// Function responsible for updating an exam type
export const updateExamType = async payload => {
  const response = client.schoolClient.put("update-exam-type", payload);

  return response;
};

/// Function responsible for deleting exam type from the DB
export const deleteExamType = async payload => {
  const response = client.schoolClient.delete("delete-exam-type", {
    data: payload,
  });

  return response;
};

// ------------------- HANDLE EXAM TYPES - ENDS ------------------- //

export const addSubjects = async payload => {
  const response = client.schoolClient.post("create-subjects", payload);

  return response;
};
/// ------------------------ SESSIONS - START ------------------------ ///

export const activateASession = async payload => {
  const response = client.schoolClient.post(
    "class-session-onboarding",
    payload
  );
  return response;
};

/// This function is responsible for updating the status of a session
export const toggleASession = async payload => {
  const response = client.schoolClient.patch("toggle-class-session", payload);
  return response;
};

///------------------------ GRADES/COMMENT - BEGINS  ------------------------///

export const addGrade = async payload => {
  const response = client.schoolClient.post(`create-comment`, payload);
  return response;
};

export const getAllGrades = async () => {
  const response = await client.schoolClient.get(`get-all-comments`);
  return response;
};

export const getSingleGrade = async commentId => {
  const response = await client.schoolClient.get(
    `get-single-comment?commentId=${commentId}`
  );
  return response;
};

export const updateSingleGrade = async payload => {
  const response = await client.schoolClient.put(`update-comments`, payload);
  return response;
};

/// Function responsible for deleting school grade comment
export const deleteSingleGrade = async payload => {
  const response = await client.schoolClient.delete(`delete-comments`, {
    data: payload,
  });
  return response;
};

/// Function responsible for editing the principal's comment of a student's result
export const updateStudentResultComment = async payload => {
  const response = await client.schoolClient.put(
    "update-result-custom-comment",
    payload
  );

  return response;
};

///------------------------ GRADES/COMMENT - ENDS  ------------------------///

/// Function for updating a student profile
export const updateStudentProfile = async payload => {
  const response = client.schoolClient.put("update-student", payload);

  return response;
};
export const updateClassSubjects = async payload => {
  const response = client.schoolClient.put(
    "create-or-update-subjects",
    payload
  );

  return response;
};

/// Function for updating a teacher's profile
export const updateTeacherProfile = async payload => {
  const response = client.schoolClient.put("update-teacher", payload);

  return response;
};

//////////////////////////////////////
export const getAllTheTeachers = async page => {
  page = parseInt(page);
  // const response = client.schoolClient.get(
  //   `https://bt-school-service.herokuapp.com/get-all-teachers?page=${page}`
  // );
  const response = client.schoolClient.get(`get-all-teachers?page=${page}`);

  return response;
};

/// Function to get all sessions for a school
export const getAllClassSession = async (page = 1) => {
  const queryParameter = {
    page: +page,
  };

  const response = client.schoolClient.get("class-sessions", {
    params: queryParameter,
  });

  return response;
};

export const getFilteredTeachers = async (
  page = 1,
  classFilter,
  subjectFilter
) => {
  let queryParameters = {
    page: +page,
    teacherClass: classFilter,
    subject: subjectFilter,
  };

  queryParameters = removeEmptyKeyValuesFromObject(queryParameters);

  const response = client.schoolClient.get("sort-teachers", {
    params: queryParameters,
  });

  return response;
};

/////////////////////////////////////////////////
export const getAllFilteredTeachers = async (
  page = 1,
  filterClass,
  filterSubjects
) => {
  page = parseInt(page);

  // let link = `https://bt-school-service.herokuapp.com/sort-teachers?page=${page}`;
  let link = `sort-teachers?page=${page}`;

  if (filterClass === "" || filterClass === undefined) {
  } else {
    link = `${link}&teacherClass=${filterClass}`;
  }
  if (filterSubjects === "" || filterSubjects === undefined) {
  } else {
    link = `${link}&subject=${filterSubjects}`;
  }

  const response = client.schoolClient.get(link);

  return response;
};

/// This function is responsible for fetching students result
export const getSchoolStudentsResults = async (
  page = 1,
  studentTerm,
  studentClass,
  studentYear
) => {
  const queryParameters = {
    page: +page,
    studentTerm: studentTerm,
    studentClass: studentClass,
    studentYear: +studentYear,
  };

  debugPrint(
    "schoolService - getSchoolStudentsResults -- queryParameters ->",
    queryParameters
  );

  const response = client.schoolClient.get("get-students-results", {
    params: queryParameters,
  });

  return response;
};

/// This function is for fetching a single student's result
export const getSingleStudentResult = async (
  studentAuthId,
  studentClass,
  studentSubject,
  examType,
  studentTerm,
  studentYear
) => {
  const queryParameters = {
    studentAuthId: studentAuthId,
    studentClass: studentClass,
    studentSubject: studentSubject,
    examType: examType,
    studentTerm: studentTerm,
    studentYear: studentYear,
  };

  const response = client.schoolClient.get(
    "get-single-student-results-by-subjects",
    { params: queryParameters }
  );

  return response;
};

/// Function for getting all the subjects a school offers
export const getAllSchoolSubjects = async schoolAuthId => {
  const queryParameter = {
    schoolAuthId: schoolAuthId,
  };

  const response = client.schoolClient.get("get-all-subjects", {
    params: queryParameter,
  });

  return response;
};

/// Function for getting results of students
export const getStudentResultsBySubjects = async (
  studentClass,
  studentSubject,
  examType,
  studentTerm,
  studentYear
) => {
  const queryParameters = {
    studentClass: studentClass,
    studentSubject: studentSubject,
    examType: examType,
    studentTerm: studentTerm,
    studentYear: +studentYear,
  };

  const response = client.schoolClient.get("get-results-by-subjects", {
    params: queryParameters,
  });

  return response;
};

/// Function for submitting bulk student results
export const uploadBulkStudentResult = async payload => {
  const response = client.schoolClient.post(
    "bulk-student-scores-upload",
    payload
  );

  return response;
};

/// Function for submitting a single student's result
export const singleStudentResultUpload = async payload => {
  const response = client.schoolClient.post("upload-student-scores", payload);

  return response;
};

/// Function for downloading bulk student excel sheet
export const getStudentExcelTemplate = async () => {
  const response = client.schoolClient.get("get-student-excel-template", {
    responseType: "blob",
  });

  return response;
};

export const uploadStudentsExcelBulkUpload = async formData => {
  const header = {
    "Content-Type": "multipart/form-data",
  };

  const response = client.schoolClient.post(
    "student-excel-bulk-account-creation",
    formData,
    { headers: header }
  );

  return response;
};

export const downloadStudentPdfResultSchool = async (
  studentAuthId,
  studentClass,
  studentTerm,
  studentYear
) => {
  const queryParameters = {
    studentAuthId,
    studentClass,
    studentTerm,
    studentYear,
  };

  const response = client.schoolClient.get("download-result-sheet", {
    responseType: "blob",
    params: queryParameters,
  });

  return response;
};

///------------------------ PROMOTION ------------------------///

/// This function is responsible for fetching students data to be promoted
export const fetchPromotionAndOrPublishStudents = async (
  studentClass,
  studentTerm,
  studentYear,
  promote = false,
  publish = false
) => {
  const queryParameters = {
    studentClass,
    studentTerm,
    studentYear,
    promote,
    publish,
  };

  const response = client.schoolClient.get("sort-promoted-and-published", {
    params: queryParameters,
  });
  return response;
};

/// This function is responsible for promoting bulk students
export const promoteBulkStudents = async payload => {
  const response = client.schoolClient.post("promote-students", payload);

  return response;
};

/// This function is responsible for publishing students' result
export const publishStudentResults = async payload => {
  const response = client.schoolClient.post("publish-result", payload);

  return response;
};

/// Function responsible
export const postSupportRequestSchool = async payload => {
  const response = client.schoolClient.post("request-support", payload);

  return response;
};

///------------------------ WALLET ------------------------///

/// Function responsible for making wallet payment
export const fundWallet = async payload => {
  const response = client.schoolClient.post("fund-wallet", payload);

  return response;
};

/// Function responsible for getting school wallet details
export const getSchoolWalletDetails = async () => {
  const response = client.schoolClient.get("get-school-wallet");

  return response;
};

// Function to get payroll of a school
export const getSchoolPayroll = async payload => {
  const response = client.schoolClient.get("get-payrolls");

  return response;
};

export const getAllSchoolPayrolls = async payload => {
  const response = client.schoolClient.get("get-all-school-payrolls", payload);

  return response;
};

export const getAllSchoolTeachers = async () => {
  const response = client.schoolClient.get("get-all-teachers-and-staffs");

  return response;
};

// {
//   "year": 2023,
//   "month": "october",
//   "role": "teacher",
//   "arrayOfRemovedStaffIds": [
//     "6538f7acb03c621a4565a758",
//     "6538f7acb03c621a4565a758"
//   ]
// }

export const createPayroll = async payload => {
  const response = client.schoolClient.post("prepare-payroll", payload);

  return response;
};

export const resendPayrollOtpCode = async payload => {
  const response = client.schoolClient.post("resend-payroll-otp-code", payload);

  return response;
};

export const approvePayroll = async payload => {
  const response = client.schoolClient.patch("approve-payroll", payload);

  return response;
};

/**
 * @typedef {Object} MakePayrollPayload
 * @property {string} payrollId - The ID of the payroll
 * @property {(payNow | *)} paymentType - The payment of the payroll
 * @property {Date} [scheduleDate] - The date of the scheduled payroll
 */

/**
 * @description Function responsible for triggering a payroll
 * @param {MakePayrollPayload} payload
 * @return {Promise<AxiosResponse<any, any>>}
 */
export const makePayrollPayment = async payload => {
  const response = client.schoolClient.post("trigger-payroll", payload);

  return response;
};

/**
 * @typedef {Object} RetryPayrollPayload
 * @property {string} schoolAuthId - The ID of the school
 * @property {string} payrollId - The ID of the payroll
 *
 * @description Function responsible for retrying failed payload
 * @param {RetryPayrollPayload} payload
 * @returns {Promise<AxiosResponse<any, any>>}
 */
export const retryPayrollPayment = async payload => {
  const response = client.schoolClient.post("retry-failed-transfers", payload);

  return response;
};

/**
 * @description Function returns the list of banks in an object
 * @returns {Promise<AxiosResponse<any, any>>}
 */
export const getAllBanks = async () => {
  const response = client.schoolClient.get("list-all-banks");

  return response;
};
